import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './Home'
import { Board } from './Board'
import { User } from './User'

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="board/*" element={<Board />} />
        <Route path="user" element={<User />} />
      </Routes>
    </BrowserRouter>
  )
}