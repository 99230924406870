import React, { useState, useEffect, forwardRef, Ref } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import MaterialTable, { Icons } from 'material-table'
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { Link } from 'react-router-dom'
import './common.css'

const WrapperBoards = styled.div`
  background: rgba(255,255,255,.9);
  width: 80%;
  box-shadow: 1px,1px,5px,rgba(0,0,0,.3);
  border-radius: 10px;
  margin: 0 auto;
`

const iconComponentByTableIconType = {
  Add: AddBox,
  Check,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn,
};

const tableIcons = Object.entries(iconComponentByTableIconType).reduce(
  (currentTableIcons: Icons, [tableIconType, IconComponent]) => {
    currentTableIcons[
      tableIconType as keyof Icons
    ] = forwardRef((props, ref: Ref<SVGSVGElement>) => (
      <IconComponent {...props} ref={ref} />
    ));
    return currentTableIcons;
  },
  {}
);

const Button = styled.button`
  cursor: pointer;
`
export const Home = () => {
  const [boards, setBoards] = useState([])
  console.dir(boards)
  useEffect(() => {
    const url = 'https://cde.appare.co.uk/api.php?page=boards'
    axios.get(url)
      .then(res => {
        setBoards(res.data.all_boards)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  return (
    <WrapperBoards>
      <MaterialTable
        icons={tableIcons}
        options={{
          sorting: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40, 50],
          tableLayout: "auto"
        }}
        columns = {[
          {
            title: 'ID',
            field: 'board_id',
            width: '3em'
          },
          {
            title: 'Board Name',
            field: 'board_name',
            width: 'auto',
            render: (rowData: any) => <Link to={`/board/${rowData.board_id}`} className='board_name'>{rowData.board_name}</Link>
          },
          {
            title: 'Board Description',
            field: 'board_description',
            width: 'auto'
          },
          {
            title: 'Owner',
            field: 'owner_name',
            width: '18%'
          },
          {
            title: 'Last Update',
            field: 'updated_at',
            type: 'datetime',
            defaultSort: 'desc',
            width: '18%'
          },
          {
            title: 'Update Times',
            field: 'update_times',
            type: 'numeric',
            width: '18%'
          },
          {
            title: 'Created',
            field: 'created_at',
            type: 'datetime',
            width: '18%'
          }
        ]}
        data = {boards}
        title="All Boards"
      />
      <Button>更新</Button>
    </WrapperBoards>
  )
}
