import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import './common.css'

const BoardWrapper = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  background-attachment: scroll;
  background: url('../assets/images/back_corkbord.jpg') left top;
  background-size: auto;
`
const ControlPanel = styled.div`
  display: block;
  width: 350px;
  height: auto;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(255,255,255,.8);
`
const Card = styled.div`
  display: block;
  position: absolute;
  cursor: pointer;
`
const ButtonIcon = styled.div`
  display: block;
  cursor: pointer;
`

interface CardRecord {
  card_id: number
  put_on_board_id: number
  position_x: number
  position_y: number
  rotate: number
  z_index: number
  card_type: string
  image_name: string
  caption: string
  caption_color: string
  caption_position: string
  caption_rotate: number
  image_scale: number
  image_position_x: number
  image_position_y: number
  pin_position: string
  pin_color: string
  card_description: string
}
export const Board = () => {
  // URL取得
  const location = useLocation()

  // board ID取得
  const theBoardId = location.pathname.replace('/board/','')
  console.log(`board ID= ${theBoardId}`)
  const [arrows, setArrows] = useState([])
  const [cards, setCards] = useState([])
  console.dir(arrows)
  console.dir(cards)
  useEffect(() => {
    const url = `https://cde.appare.co.uk/api.php?page=the_board&the_board_id=${theBoardId}`
    axios.get(url)
      .then(res => {
        setArrows(res.data.arrows)
        setCards(res.data.cards)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [theBoardId])

  // ボード保存
  const urlAPI = "https://cde.appare.co.uk/api.php"
  const [text, setText] = useState<string>('')
  const [status, setStatus] = useState<[]>([])
  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>):void => {
    setText(e.target.value)
  }
  const boardSave = () => {
    console.log('Save!')
    console.log(urlAPI)
    axios.post<[]>(
      urlAPI + "?page=save_board",
      { description: text },
      { headers: { "Content-type": "application/json" } }
    )
    .then((res) => {
      setStatus(res.data)
      console.log(res.data)
      console.log(status)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  type DraggableEventHandler = (e: Event, data: DraggableData) => void | false;
  type DraggableData = {
    node: HTMLElement,
    // lastX + deltaX === x
    x: number, y: number,
    deltaX: number, deltaY: number,
    lastX: number, lastY: number
  };
  const [currentPosition, setCurrentPosition] = useState({
    xRate: 300,
    yRate: 300
  })
  const onDrag = (e:DraggableEvent, data:DraggableData) => {
    setCurrentPosition({xRate: data.lastX, yRate: data.lastY})
  }

  return (
    <BoardWrapper>
      <ControlPanel>
        {theBoardId}
        <label>
          Description:
          <input type="text" name="description" onChange={onChangeText} />
        </label>
        <ButtonIcon onClick={boardSave}>Save</ButtonIcon>
      </ControlPanel>
      {cards && cards.map((card: CardRecord) => (
        <Draggable
          position={{
            x: currentPosition.xRate,
            y: currentPosition.yRate
          }}
          key={card.card_id}
          onDrag = {onDrag}
        >
          <Card className='card'>{card.card_id}</Card>
        </Draggable>
      ))}
    </BoardWrapper>
  )
}
